var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-data-table',{attrs:{"dense":"","headers":[{text:_vm.$t('label.type'), value:'kind_name'}, {text:_vm.$t('label.castpoint'), value:'id_castpoint'}, {text:_vm.$t('label.startAt'), value:'time_start'},{text:_vm.$t('label.endAt'), value:'time_end'},{text:_vm.$t('label.value'), value:'value'}, {text:'',value:'id'} ],"items":_vm.weventSuggests,"group-by":"group_key","sort-by":"time_start","item-key":"id","hide-default-footer":true,"disable-pagination":true},scopedSlots:_vm._u([{key:"group.header",fn:function(ref){
var group = ref.group;
var toggle = ref.toggle;
var isOpen = ref.isOpen;
return [_c('td',{staticClass:"pl-2",attrs:{"colspan":"6"}},[(isOpen)?_c('v-icon',{ref:'SG_' + group,staticClass:"mr-1",attrs:{"small":"","color":"primary"},on:{"click":toggle}},[_vm._v("mdi-minus")]):_c('v-icon',{staticClass:"mr-1",attrs:{"small":"","color":"primary"},on:{"click":toggle}},[_vm._v("mdi-plus")]),(_vm.weventKind(group.replace(/\d{3}##(.*)/g, '$1')).is_alert)?_c('v-icon',{staticClass:"mr-1",attrs:{"color":_vm.gWeventColorSeverity(3),"small":""}},[_vm._v(_vm._s(_vm.gWeventIcon(group.replace(/\d{3}##(.*)/g, '$1'))))]):_vm._e(),_c('span',[_vm._v(_vm._s(_vm.weventKind(group.replace(/\d{3}##(.*)/g, '$1')).description))])],1)]}},{key:"item.id_castpoint",fn:function(ref){
var item = ref.item;
return [_c('span',[_vm._v(_vm._s(_vm.currentZone.castpoints.find(function (cp) { return cp.id == item.id_castpoint; }).name))]),_c('v-btn',{staticClass:"ml-1",attrs:{"text":"","x-small":"","color":"lightgrey"},on:{"click":function($event){$event.stopPropagation();return _vm.gForecastCastpoint(item.id_castpoint)}}},[_c('v-icon',{attrs:{"x-small":"","color":"primary"}},[_vm._v("mdi-chart-areaspline")])],1)]}},{key:"item.time_start",fn:function(ref){
var item = ref.item;
return [_c('span',[_vm._v(_vm._s(_vm.$moment(item.time_start *1000).tz(_vm.currentZone.timezone).format('DD/MM/YYYY HH:mm')))])]}},{key:"item.time_end",fn:function(ref){
var item = ref.item;
return [_c('span',[_vm._v(_vm._s(_vm.$moment(item.time_end *1000).tz(_vm.currentZone.timezone).format('DD/MM/YYYY HH:mm')))])]}},{key:"item.value",fn:function(ref){
var item = ref.item;
return [(item.value && _vm.getWeventCat(_vm.weventKind(item.id_kind).cat))?[_c('span',[_vm._v(_vm._s(item.value))]),(_vm.weventKind(item.id_kind).cat)?_c('span',[_vm._v(" "+_vm._s(_vm.getWeventCat(_vm.weventKind(item.id_kind).cat).unit))]):_vm._e()]:_c('span',[_vm._v("-")])]}},{key:"item.id",fn:function(ref){
var item = ref.item;
return [_c('v-btn',{attrs:{"x-small":""},on:{"click":function($event){return _vm.generate(item)}}},[_vm._v(_vm._s(_vm.$t('button.generate')))])]}}])})}
var staticRenderFns = []

export { render, staticRenderFns }