<template lang="pug">
  div.text-center
    span.text-overline.font-weight-bold(:class='`${wevent.color}--text`')
      | {{ weventText }}
    br
    span
      | {{ weventTimeText }}
    span(v-if="!wevent.futur")
      v-progress-linear(:color="weventColorSeverity" :value="wevent.progress")
    br
    | {{ $moment(wevent.time_start * 1000).format("DD/MM HH:mm") }}
    br
    | {{ $moment(wevent.time_end * 1000).format("DD/MM HH:mm") }}
</template>
<script>
export default {
  props: {
    wevent: {
      type: Object,
      default: null
    },
  },
  computed: {
    weventColorSeverity() {
      return this.gWeventColorSeverity(this.wevent.level)
    },
    weventText() {
      return this.weventKind(this.wevent.type).description
    },
    weventTimeText() {
      if(this.wevent.futur) {
        return this.$moment(this.wevent.time_start *1000).fromNow()
      }
      else {
        return this.$t('wevent.tooptip.since',
            {
              from: Math.round(this.$moment.duration(this.wevent.since,'seconds').asHours()),
              duration: Math.round(this.$moment.duration(this.wevent.total,'seconds').asHours())
            })
      }
    }

  }
}
</script>
<style>
</style>
