<template lang="pug">
v-row(v-if="currentZone")
  v-col(cols="12")
    v-card(color="grey lighten-5")
      v-card-title
        | {{ $t("label.bulletinEnCours") }}
      v-card-text
        v-simple-table
          thead
            tr
              th
              th {{ $t("label.zoneBulletin") }}
              th {{ $t("channel") }}
              th {{ $t("text") }}
              th {{ $t("publishAt") }}
              th.bulletin-action-witdh
          tbody
            tr(v-for="(bulletin, b) in bulletinList" :key="b" v-if="bulletin")
              td
                channel-icon(:zone="currentZone" :channel="b" :showDraft="bulletin && bulletin.draft === true")
              td
                | {{ gGetZoneById(bulletin.id_castzone).name }}
                v-icon.mt-n1.pl-2(v-if="currentZone.id!=bulletin.id_castzone" color="grey lighten-1" small @click="zoneParent({id:bulletin.id_castzone,show:true,route:true})") mdi-arrow-up-circle-outline
              td {{ $te('channel_list.' + bulletin.channel) ? $t('channel_list.' + bulletin.channel) : bulletin.channel }}
              td
                v-tooltip(v-if="bulletin.txt && bulletin.txt.length>20" bottom max-width="50vw" )
                  template(v-slot:activator="{ on, attrs }")
                    span.cursor-default(v-bind="attrs" v-on="on")
                      | {{ truncateString(bulletin.txt, 20) }}
                  span.text-block
                    | {{ bulletin.txt }}
                span(v-else)
                  | {{ bulletin.txt }}
              td {{ $moment(bulletin.time_publish *1000).tz(currentZone.timezone).format('DD/MM/YYYY HH:mm') }}
              td
  v-col(ref="bulletinsFormCol" cols="12")
    v-col.text-right(cols="12")
      v-btn.mr-3.white--text(small color="primary" :disabled="gIsLoading || !!doing" @click="generateAll(true)" :loading="doing=='renew_all'")
        |{{ $t("button.renewAll") }}
      v-btn.mr-3.white--text(small color="primary" :disabled="gIsLoading || !!doing" @click="generateAll()" :loading="doing=='generate_all'")
        |{{ $t("button.generateAll") }}
      v-btn.white--text(v-show="forms.length > 1" small color="primary" :disabled="gIsLoading || disablePublish || !!doing" @click="publishall()" :loading="doing=='publish_all'")
        |{{ $t("button.publishall") }}
    v-col(v-for="(channel, i) in forms" :key="i" cols="12")
      v-card(color="grey lighten-5")
        v-card-title
          channel-icon.pr-2(:zone="currentZone" :channel="channel.channel")
          span.mr-3.card__title
            | {{ $t("newBulletin") }} {{ $te('channel_list.' + channel.channel) ? $t('channel_list.' + channel.channel) : channel.channel }}
          span.actions
            span.actions__group
              v-btn( @click="generate(channel, true)" small outlined :disabled="!currentZone.bulletins[channel.channel] || gIsLoading || !!doing" :loading="doing=='renew_' + channel.channel")
                v-icon.pr-1(small) mdi-backup-restore
                |{{ $t("button.renew") }}
              v-tooltip(top)
                template(v-slot:activator="{ on, attrs }")
                  v-btn(v-bind="attrs" v-on="on" @click="empty(channel)" small outlined :disabled="(channel.txt!=null && channel.txt.length>0) || !!doing")
                    v-icon.pr-1(small) mdi-thumb-up-outline
                    | {{ $t("button.ras") }}
                | {{ $t('msg.empty') }}
              v-btn( @click="generate(channel)" small outlined :disabled="gIsLoading || !!doing" :loading="doing=='generate_' + channel.channel")
                v-icon.pr-1(small) mdi-cog-outline
                |{{ $t("button.generate") }}
              v-btn( @click="copy(channel)" small outlined :disabled="gIsLoading || !!doing" )
                v-icon.pr-1(small) mdi-content-copy
                |{{ $t("button.copy") }}
            span.actions__group
              v-btn( @click="publish(channel)" small :disabled="gIsLoading || !!doing || disablePublishForm(channel)" outlined :loading="doing=='publish_' + channel.channel")
                v-icon.pr-1(small) mdi-check
                | {{ $t("button.publish") }}
        v-card-text
          // à voir si on propose de forcer une date de publication
          // <v-text-field filled type="date" v-model="channel.dt_expire" />
          v-textarea(:id="'bulletin'+channel.channel+'text'" class="v-textarea--bulletins" v-model="channel.txt" filled auto-grow row-height="26" clearable rows="7" cols="30" )
    v-snackbar(v-model="success" bottom color="success")
      | {{ $t('save.success') }}
    v-snackbar(v-model="error" bottom color="error")
      | {{ $t('save.error') }}
</template>

<script>
import api from '@/api'
import 'tiptap-vuetify/dist/main.css'
import {mapActions, mapState} from 'vuex'
import ChannelIcon from '@/components/Castzones/ChannelIcon'

export default {
  components: {ChannelIcon},
  data() {
    return {
      success:false,
      error:false,
      forms: [],
			doing: null,
    }
  },
  computed: {
    ...mapState(['zone']),
    disablePublish() {
      for(let form of this.forms) {
				if(!this.disablePublishForm(form)) return false
			}
			return true			
    },
    bulletinList() {
      return Object.keys(this.currentZone.bulletins).sort().reverse().reduce(
          (obj, key) => {
            obj[key] = this.currentZone.bulletins[key]
            return obj
          },
          {}
      )
    },
  },
  watch: {
    'currentZoneId': {
      immediate: true,
      handler() {
        this.loadZoneForms()
      }
    },
    'editZone': {
      immediate: true,
      handler(val) {
        val ? this.loadZoneForms() : this.saveDraft()
      }
    },
  },
  methods: {
    ...mapActions(['createBulletin', 'createBulletinDraft', 'updateBulletinDraft', 'publishBulletinDraft', 'deleteBulletinDraft']),
    disablePublishForm(form) {
      let disable = false
      let parent = this.currentZone.parent
      while(parent) {
        const draft = this.gGetDraftById(parent.bulletinsDraft[form.channel])
        if(draft && draft.id_castzone==parent.id ) disable = true
        parent = parent.parent
      }
      return disable || !form.txt
    },
    truncateString(str, num) {
      if (str.length <= num) {
        return str
      }
      return str.slice(0, num) + '...'
    },
    loadZoneForms() {
      this.forms = []
      if(!this.currentZone || !this.currentZone.bulletins) return
      for(let channel of Object.keys(this.currentZone.bulletins).sort().reverse()) {
        let txt = ''
        let id = null
        if(this.currentZone.bulletinsDraft[channel]  && this.currentZone.bulletinsDraft[channel].id_castzone == this.currentZone.id) {
            txt = this.currentZone.bulletinsDraft[channel].txt
            id = this.currentZone.bulletinsDraft[channel].id
        }
        this.forms.push({
            id_castzone: this.currentZone.id,
            channel,
            txt,
            oldTxt: txt,
            id,
          })
      }
    },
    focusChannel(channel) {
      const elText = this.$refs.bulletinsFormCol.querySelector('#bulletin'+channel.channel+'text')
      elText && elText.focus()
    },
    empty(channel) {
      channel.txt = this.$t('msg.empty')
      this.focusChannel(channel)
    },
    async generateAll(renew=false) {
			this.doing = renew ? 'renew_all' : 'generate_all'
			const castzones = [this.currentZone.id]
			let parent = this.currentZone.parent
      while(parent) {
        castzones.push(parent.id)
        parent = parent.parent
      }
			const suggests = await api.castzones.getBulletinsSuggest(this.currentZone.id, castzones,  null, renew)
			if(suggests && suggests.length>0) {
				for(let channel of this.forms) {
					const suggest = suggests.find(s => s.channel == channel.channel)
					if(suggest && suggest.text) {
						channel.txt = channel.txt ? channel.txt + '\n' + suggest.text : suggest.text
					}
				}	
			}
			this.doing = null
    },		
    async generate(channel, renew=false) {
			this.doing = (renew ? 'renew' : 'generate') + '_' + channel.channel
			const castzones = [this.currentZone.id]
			let parent = this.currentZone.parent
      while(parent) {
        castzones.push(parent.id)
        parent = parent.parent
      }
			const suggests = await api.castzones.getBulletinsSuggest(this.currentZone.id, castzones, channel.channel, renew)
			if(suggests && suggests.length>0) {
				const suggest = suggests.find(s => s.channel == channel.channel)
				if(suggest && suggest.text) {
					channel.txt = channel.txt ? channel.txt + '\n' + suggest.text : suggest.text
				}
			}
      this.focusChannel(channel)
			this.doing = null
    },
		copy(channel) {
      if(!this.currentZone.bulletins[channel.channel]) return

      if(channel.txt && channel.txt.length>0) channel.txt+= '\n'
      channel.txt = [channel.txt, this.currentZone.bulletins[channel.channel].txt].join('')
      this.focusChannel(channel)
    },
    async publishall() {
			for (var i = 0; i < this.forms.length; i++) {
        await this.publish(this.forms[i], 'publish_all')
      }
    },
    async saveDraft() {
      this.gLoading('Sauvegarde des brouillons')
      try{
        for(let i=0; i <this.forms.length; i++) {
          const bDelete = !this.forms[i].txt
          const bModif = !bDelete && this.forms[i].txt != this.forms[i].oldTxt
          if(this.forms[i].id) {
            if(bModif) {
              await this.updateBulletinDraft(this.forms[i])
            } else if(bDelete){
              await this.deleteBulletinDraft(this.forms[i])
            }
          } else if(bModif) {
            await this.createBulletinDraft(this.forms[i])
          }
        }
        this.loadZoneForms()
      } finally {
        this.gLoadingEnd()
      }
    },
    async zoneParent(data) {
      await this.saveDraft()
      this.gLoadZone(data)
    },
    async publish(form, doing = null) {
      if (form.txt) {
				this.doing= !doing ? 'publish_' + form.channel : doing
				this.gLoading()
        try{
          if(form.id) {
            await this.publishBulletinDraft(form)
          } else {
            await this.createBulletin(form)
          }
          this.success = true
          form.txt = ''
          form.id = null
        } catch(err){
          this.error = true
        } finally {
          this.gLoadingEnd()
					this.doing=null
        }
      }
    },
  },
}
</script>

<style>
  .bulletin-action-witdh {
    width: 50px
  }

  .text-block {
    white-space:pre-wrap;
  }

  .actions, .actions__group{
    display: flex;
    gap: 1rem;
    flex-wrap: wrap;
  }
  .actions {
    justify-content: space-between;
    flex: 1;
  }
  .v-card__title .card__title {
    min-width: 80%;
  }
  @media screen and (min-width: 1201px) {
    .v-card__title .card__title {
      min-width: 0;
    }
  }

  .v-application--is-ltr .v-textarea.v-text-field--enclosed .v-text-field__slot {
    margin-right: -40px;
  }
  .v-application--is-ltr .v-textarea.v-text-field--enclosed .v-text-field__slot textarea {
    padding-top: 10px;
    padding-bottom: 10px;
    margin-top: 0;
  }
  .v-application--is-ltr .v-textarea.v-input--is-label-active .v-text-field__slot textarea {
    margin-top: 15px;
  }
  .v-textarea--bulletins .v-input__icon .v-icon:hover,
  .v-textarea--bulletins .v-input__icon .v-icon:focus,
  .v-textarea--bulletins .v-input__icon .v-icon:active {
    border-radius: 4px;
    background-color: rgba(0, 0, 0, 0.087);
    transition-duration: 0.28s;
    transition-property: box-shadow, transform, opacity;
    transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  }
</style>
