<template lang="pug">
v-row
  v-col(cols="12")
    RangeModelSlider(v-if="points[0]" :castzone="castzone" :point="points[0]")
  v-col(v-for="(point, i) in points" :key="i" cols="12" md="12" lg="12")
    v-card(color="grey lighten-3")
      v-card-text
        ViewModelsData(:map-ref="i+1" :points="points" :point="point")
  v-col.text-right(cols="12")
    v-btn(color="primary" @click="savAll()")
      | {{ $t('btn.saveAll') }}
</template>

<script>
import { mapState } from 'vuex'
import api from '@/api'
import EventBus from '@/plugins/event-bus'
export default {
  components: {
    ViewModelsData: () => import('@/components/Modeles/ViewModelsData'),
    RangeModelSlider: () => import('@/components/Modeles/RangeModelSlider'),
  },
  data() {
    return {
      points: [],
      points_data: null,
      castzone: null,
    }
  },
  computed: {
    ...mapState(['castzones']),
  },
  watch: {
    castzones: {
      deep: true,
      handler() {
        this.getPointsData()
      },
    },
  },
  mounted() {
    this.getPointsData()
  },
  methods: {
    saveAll(){
        EventBus.$emit('saveAll')
    },
    async getPointsData() {
      try {
        let castzone = await api.castzones.getOne(this.$route.params.id)
        this.castzone = castzone
        if (castzone) {
          this.points = castzone.points
        }
      } catch (err) {
        console.log(err)
      }
    },
  },
}
</script>

<style>
</style>