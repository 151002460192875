<template lang="pug">
span
  v-tooltip(top :disabled="noTooltip" )
    template(v-slot:activator="{ on, attrs }")
      span(:class="{'wevent-inherit-icon':wevent.inherit}")
        v-badge(v-if="wevent.is_draft" dot overlap color="warning")
          v-btn.mr-3(outlined depressed fab v-bind="attrs" v-on="on"
              :color="wevent.color"
              :x-small="xsmall" :small="small" :large="large")
            v-icon {{ weventIcon }}
        v-badge(v-else-if="wevent.futur" :color="wevent.color" :content="weventBadgeText" offset-x="28" offset-y="14")
          v-btn.mr-3(outlined depressed fab v-bind="attrs" v-on="on"
              :color="wevent.color"
              :x-small="xsmall" :small="small" :large="large")
            v-icon {{ weventIcon }}
        v-btn.mr-1(v-else depressed fab v-bind="attrs" v-on="on"
            :color="wevent.color"
            :x-small="xsmall" :small="small" :large="large")
          v-icon(color="white") {{ weventIcon }}
    wevent-info(v-if="!noTooltip" :wevent="wevent")
</template>
<script>
import WeventInfo from '@/components/Castzones/WEventInfo'

export default {
  components: {
    WeventInfo,
  },
  props: {
    noTooltip: {
      type: Boolean,
      default : false
    },
    wevent: {
      type: Object,
      default: null
    },
    xsmall: {
      type: Boolean,
      default: true
    },
    small: {
      type: Boolean,
      default: false
    },
    large: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    weventIcon() {
      return this.gWeventIcon(this.wevent.type)
    },
    weventBadgeText() {
      const inH =  Math.round(this.$moment.duration(this.wevent.in,'seconds').asHours())
      return inH <= 24 ? inH + 'h' : '+24h'
    },
  }
}
</script>
<style>
.wevent-inherit-icon {
  opacity: 0.4;
}
</style>
