<template lang="pug">
v-card(v-if="currentZone")
  v-app-bar(dark color="primary")
    v-btn(icon dark @click="closeDialog()")
      v-icon mdi-close
    v-toolbar-title {{ currentZone.name }}
    v-tabs.tabscontainer(v-model="tabNext" centered)
      v-tab(v-for="(tab, idx) in tabsComponent" :key="idx" @click="changeTab(tab.name)")
        v-icon.pr-2 {{ tab.icon }}
        | {{ tab.text }}
    v-spacer
    div.pr-3 {{ $moment().tz(currentZone.timezone).format('DD/MM/YYYY HH:mm') }} - {{ currentZone.timezone }}
    v-spacer
  v-card-text.pb-0
    v-row.mt-0
      v-col(cols="12" lg="8")
        v-tabs-items(v-model="tabActive")
          v-tab-item(v-for="(tab, idx) in tabsComponent" :key="idx")
            component(:is="tab.component" :ref="tab.name")
      v-col(cols="12" lg="4")
        l-map.mapzone(ref="mapzone" :zoom="4" :center="[47, 2]" v-if="editZone && currentZone.geometry")
          l-tile-layer(url="https://tile.openstreetmap.org/{z}/{x}/{y}.png")
          l-feature-group(ref="featurezone")
            l-geo-json(ref="geomzone" :options-style="styleZone" :geojson="currentZone.geometry" @ready="fitBoundOnMap()")
          l-marker(
            v-for="(point, idx) in currentZone.castpoints"
            :key="`marker${idx}`"
            :lat-lng="[point.latitude, point.longitude]"
            @click="gForecastCastpoint(point.id)"
            )
            l-tooltip(:options="{direction:'bottom'}")
              span {{ point.name }}
            l-icon(:icon-anchor="[7,7]" :tooltip-anchor="[0,15]")
              div.marker-castpoint.d-flex.align-content-center.justify-center(:style="`background-color:${gColorCastpointMarker(point)}`" )
</template>

<script>
import {LTooltip, LMap, LTileLayer, LGeoJson, LFeatureGroup, LMarker, LIcon} from 'vue2-leaflet'
import {mapGetters} from 'vuex'
import Wevents from '@/components/Castzones/WEvents'
import Models from '@/components/Castzones/Models'
import Bulletins from '@/components/Castzones/Bulletins'

export default {
  components: {
    // ViewModelsData: () => import("@/components/Modeles/ViewModelsData"),
    Wevents,
    Models,
    Bulletins,
    LMap,
    LTileLayer,
    LMarker,
    LGeoJson,
    LFeatureGroup,
    LIcon,
    LTooltip
  },
  data() {
    return {
      timezone:null,
      countModel:1,
      models: 0,
      tabNext:null,
      tabsComponent: [
        {text: this.$tc('label.evenement',2), icon: 'mdi-weather-partly-snowy-rainy', component: Wevents, name: 'evenements'},
        {text: this.$tc('label.bulletin',2), icon: 'mdi-text-box', component: Bulletins, name: 'bulletins'},
        //{text: this.$tc('label.model',2) , icon: 'mdi-format-list-checkbox', component: Models, name: 'modeles'},
      ],
    }
  },
  computed: {
    ...mapGetters(['currentZone']),
    tabActive: {
      get () { return this.$store.state.tabActive },
      set (value) { this.gSetKey('tabActive', value) }
    },
    styleZone() {
       return this.gStyleZoneGeoJson(this.currentZone)
    },
  },
  watch: {
    currentZone: {
      immediate: true,
      handler(zone) {
        if (!zone) this.editZone = false
        this.fitBoundOnMap()
        this.$moment.tz.setDefault(zone.timezone)
      }
    }
  },
  mounted() {
    this.tabNext = this.tabActive
  },
  methods: {
    changeTab(name) {

      let tabNameIndex = null
      this.tabsComponent.map((t,i) => (t.name == name) ? tabNameIndex = i : false )

      if (this.tabActive != tabNameIndex) {

        const tabActiveName = this.tabsComponent[this.tabActive].name
        const tabNextName = this.tabsComponent[tabNameIndex].name
        let loadedComponent = this.$refs[tabActiveName][0]

        // check component state before change tab & save if needed

        if (tabActiveName == 'evenements') {

          if (loadedComponent.canClose()) {
            loadedComponent.saveDraft()
            this.tabActive = tabNameIndex
            this.$router.push(`/castzone/${this.currentZone.id}/${tabNextName.toLowerCase()}`)
          } else {

            this.$nextTick(() => {
              this.tabNext = this.tabActive
            })
          }
        }

        if (tabActiveName == 'bulletins') {
          loadedComponent.saveDraft()
          this.tabActive = tabNameIndex
          this.$router.push(`/castzone/${this.currentZone.id}/${tabNextName.toLowerCase()}`)
        }
      }

    },
    closeDialog() {
      const tabName = this.tabsComponent[0].name
      if(this.$refs[tabName] && this.$refs[tabName][0].canClose()) {
        this.editZone = false
        this.gCheckRoute()
      } else {
        this.changeTab(tabName)
      }
    },
    fitBoundOnMap() {
      setTimeout(() => {
        if(this.$refs.mapzone) {
          // invalidate size to force redraw (size could be wrong due to map in v-dialog)
          // see : https://stackoverflow.com/questions/56364129/vue2-leaflet-map-not-showing-properly-in-boostrapvue-modal/56364130#56364130
          // also see : https://stackoverflow.com/questions/36246815/data-toggle-tab-does-not-download-leaflet-map
          this.$refs.mapzone.mapObject.invalidateSize()
          this.$refs.mapzone.mapObject.fitBounds(this.$refs.featurezone.mapObject.getBounds())
        }
      }, 10)
    },
  }
}
</script>

<style lang="scss">
.mapzone {
  height: calc(100vh - 90px) !important;
}
.tabscontainer {
  max-width: 650px;
}

.marker-castpoint {
  height: 15px;
  width: 15px;
  border-radius: 50%;
}
</style>
