<template lang="pug">
span
  span(v-show="isDraft")
    v-tooltip(top color="white" open-delay='300')
      template(v-slot:activator="{ on, attrs }")
        v-badge(dot overlap color="warning")
          v-icon.mr-1(v-bind="attrs" v-on="on" :color="color" dense)
            | {{ icon }}
      span.grey--text.text--darken-3(v-html="text")
  span(v-show="!isDraft")
    v-tooltip(top color="white" open-delay='300')
      template(v-slot:activator="{ on, attrs }")
        v-icon.mr-1(v-bind="attrs" v-on="on" :color="color" dense)
          | {{ icon }}
      span.grey--text.text--darken-3(v-html="text")
</template>

<script>

export default {
  name: 'ChannelIcon',
  props: {
    zone: {
      type: Object,
      default: null
    },
    channel: {
      type: String,
      default: null
    },
    showDraft: {
      type: Boolean,
      default: true
    }
  },
  data() {
    return {
      icons:{
        default: 'mdi-image-broken-variant',
        road: 'mdi-road-variant',
        movie: 'mdi-movie-open',
        event: 'mdi-music-box'
      }
    }
  },
  computed: {
    icon() {
      return (this.channel && this.icons[this.channel]) ? this.icons[this.channel] : this.icons['default']
    },
    bulletin() {
      if(!this.zone || !this.zone.bulletins || !this.zone.bulletins[this.channel]) return null
      if(this.zone.bulletins[this.channel].id) return this.zone.bulletins[this.channel]
      return (this.bulletins[this.zone.bulletins[this.channel]]) ? this.bulletins[this.zone.bulletins[this.channel]] : null
    },
    isDraft() {
      return this.draft !== null
    },
    draft() {
      if(!this.showDraft || !this.zone || !this.zone.bulletinsDraft || !this.zone.bulletinsDraft[this.channel]) return null
      const id = this.zone.bulletinsDraft[this.channel].id || this.zone.bulletinsDraft[this.channel]
      return (this.bulletinsDraft[id]) ? this.bulletinsDraft[id] : null
    },
    color() {
      const infos = this.draft || this.bulletin || {time_publish:null}
      const timeInfo = this.gGetTimeInfo(this.draft ? infos.time_create : infos.time_publish)
      return (infos.id_castzone && this.zone && infos.id_castzone != this.zone.id) ? timeInfo.colorInherit : timeInfo.color
    },
    text() {
      if(this.isDraft) return `<b class="grey--text text--darken-2 subtitle-2">${this.$t('label.draft')}</b> <div class="d-flex top--divider">${this.draft.txt.replaceAll('\n', '<br/>')}</div>`

      let time = '', textTimed = '', textChannel = ''
      if(this.icon == this.icons.default) textChannel = '<b>' + (this.$te('channel_list.' + this.channel) ? this.$t('channel_list.' + this.channel) : this.channel) + ' </b><br/>'

      if(this.bulletin) {
        time = this.bulletin.time_publish
        textTimed = ((time > this.utcTime) ? this.$t('label.justnow') : this.$moment.duration(time - this.utcTime, 'seconds').humanize(true))
        return `${textChannel}<b class="grey--text text--darken-2 subtitle-2">${this.$t('label.published')} ${textTimed}</b> <div class="d-flex top--divider bulletin__text">${this.bulletin.txt.replaceAll('\n', '<br/>')}</div>`
      }

      return textChannel + this.$t('label.notEnabled')
    }
  }
}
</script>

<style>
</style>
