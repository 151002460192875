<template lang="pug">
v-container.wevents-content(v-if="currentZone && weventKinds")
  v-row
    v-col.text-right(cols="12")
      v-btn.mr-2( @click.stop="gForecastCastzone(currentZone.id)" color="lightgrey")
        v-icon(color="primary" ) mdi-chart-areaspline
      v-btn.mr-2(color="primary" :disabled="gIsLoading || !needPublish " @click="publish()")
        v-progress-circular(v-show="gIsLoading" size="20" indeterminate)
        | {{ $t("button.publish") }}

  v-row
    v-col(cols="12")
      v-card(color="grey lighten-5")
        v-card-title
          | {{ $tc("label.evenementList", weventsZone.length) }}
        v-card-text
          v-simple-table
            thead
              tr
                th {{ $t('label.published') }}
                th {{ $t('label.enabled') }}
                th {{ $t('label.zoneEvenement') }}
                th {{ $t('label.type') }}
                th {{ $tc('label.alert', 1) }}
                th {{ $t('label.startAt') }}
                th {{ $t('label.endAt') }}
                th.wevent-archive-witdh
            tbody
              tr(v-for="(wevent, i) in weventsZone" :key="i" :class="{'grey--text lighten-2': !( (wevent.is_active && wevent.will_be_active == null ) || (wevent.will_be_active))}")
                td
                  template(v-if="!wevent.is_draft")
                    template(v-if="weventsForm.find( wf=> wf.id_parent == wevent.id && wevent.id_castzone == currentZone.id )" class="green--text")
                      span(class="text-decoration-line-through")
                        | {{ $t('label.on') }}
                      span(class="red--text")
                        | &nbsp;{{ $t('label.archived') }}
                    span(v-else)
                      | {{ $t('label.on') }}
                  span(v-else)
                    | {{ $t('label.off') }}
                td(v-if="wevent.will_be_active != null && wevent.is_active != wevent.will_be_active && (wevent.is_active != null || wevent.will_be_active == false)" )
                  template(v-if="(wevent.is_active || wevent.will_be_active == false )")
                    span(class="text-decoration-line-through")
                      | {{ $t('label.on') }}
                    span(class="blue--text")
                      | &nbsp;{{ $t('label.off') }}
                  template(v-else)
                    span(class="text-decoration-line-through")
                      | {{ $t('label.off') }}
                    span(class="blue--text")
                      | &nbsp;{{ $t('label.on') }}
                td(v-else)
                  template(v-if="(wevent.is_active || (wevent.is_active == null && wevent.will_be_active))")
                    | {{ $t('label.on') }}
                  template(v-else)
                    | {{ $t('label.off') }}
                td {{ gGetZoneById(wevent.id_castzone).name }}
                  v-icon.mt-n1.pl-2(v-if="currentZone.id!=wevent.id_castzone" color="grey lighten-1" small @click="moveToZone(wevent.id_castzone)") mdi-arrow-up-circle-outline
                td
                  template(v-if="wevent.cat")
                    span
                      | {{ weventKind(wevent.type).description }}
                      | {{ wevent.value_min }} - {{ wevent.value_max }} {{ wevent.cat.unit }}
                  template(v-else)
                    | {{ weventKind(wevent.type).description }}
                td
                  v-tooltip(v-if="wevent.message" bottom)
                    template(v-slot:activator="{ on, attrs }")
                      span(v-bind="attrs" v-on="on")
                        | {{ alertsSeverityLabel(wevent.severity) }}
                        v-icon.ml-1.cursor-pointer(small) mdi-message-text-outline
                    | {{ wevent.message }}
                  template(v-else)
                    | {{ alertsSeverityLabel(wevent.severity) }}
                td {{ $moment(wevent.time_start *1000).tz(currentZone.timezone).format('DD/MM HH:mm') }}
                td {{ $moment(wevent.time_end *1000).tz(currentZone.timezone).format('DD/MM HH:mm') }}
                td(class="actions")
                  div.d-flex.align-center
                    v-btn.mr-2(x-small :disabled="!canRenew(wevent)" @click="renew(wevent)")
                      v-icon(small) mdi-pencil
                    v-btn.mr-2(v-if="false" x-small @click="clone(wevent)")
                      v-icon(small) mdi-content-copy
                    template(v-if="!wevent.inherit")
                      v-btn(color="error" x-small @click="archive(wevent.id)")
                        | {{ $t('label.archive') }}
                    template(v-else)
                      v-btn(v-if="(wevent.is_active != true && wevent.will_be_active != true) || wevent.will_be_active === false" x-small @click="setState(wevent, true)"  :disabled="(wevent.is_active === false && wevent.is_active_set_by_me === false)|| (wevent.will_be_active === false && wevent.will_be_active_set_by_me === false)")
                        | {{ $t('label.enable') }}
                      v-btn(v-else x-small @click="setState(wevent, false)" :disabled="(wevent.is_active === false && wevent.is_active_set_by_me === false ) || (wevent.will_be_active === false && wevent.will_be_active_set_by_me === false)")
                        | {{ $t('label.disable') }}

  v-row(v-if="weventSuggestsZone.length")
    v-col(cols="12")
      v-card(color="grey lighten-5")
        v-card-title
          | {{ $tc("label.evenementSuggests", weventSuggestsZone.length) }}
        v-card-text
          wevent-suggest-table(:weventSuggests="weventSuggestsZone" @generate="generateFromSuggest")

  v-row
    v-col(cols="12")
      v-card(color="grey lighten-5")
        v-card-title
          | {{ $tc("label.evenementUpdate", weventsZoneUpdate.length + weventsForm.length + weventsZoneWillArchive.length) }}
        v-card-text
          ul
            li(v-for="(wevent,i) in weventsZoneWillArchive" :key="i")
              | {{ $t('label.willBeArchived', { event: weventKind(wevent.type).description, from: $moment(wevent.time_start *1000).tz(currentZone.timezone).format('DD/MM/YYYY HH:mm'), to: $moment(wevent.time_end *1000).tz(currentZone.timezone).format('DD/MM/YYYY HH:mm')}) }}
            li(v-for="(wevent,i) in weventsZoneUpdate" :key="i")
              template(v-if="wevent.will_be_active")
                | {{ $t('label.willBeEnabled', { event: weventKind(wevent.type).description, from: $moment(wevent.time_start *1000).tz(currentZone.timezone).format('DD/MM/YYYY HH:mm'), to: $moment(wevent.time_end *1000).tz(currentZone.timezone).format('DD/MM/YYYY HH:mm')}) }}
              template(v-else)
                | {{ $t('label.willBeDisabled', { event: weventKind(wevent.type).description, from: $moment(wevent.time_start *1000).tz(currentZone.timezone).format('DD/MM/YYYY HH:mm'), to: $moment(wevent.time_end *1000).tz(currentZone.timezone).format('DD/MM/YYYY HH:mm')}) }}
            li(v-if="weventsForm.length>0")
              | {{ $tc('label.willBeAdded', weventsForm.length, {count: weventsForm.length}) }}

  v-row
    v-col(cols="12")
      v-card(color="grey lighten-5")
        v-card-title
          | {{ $tc("label.evenementNew", weventsForm.length) }}
        v-card-text
          v-form(ref="form")
            v-row
              v-col.text-right(cols="12")
                v-btn.ml-2(@click="add()")
                  v-icon mdi mdi-plus
                  |  {{ $t("button.add") }}
            v-row.mt-4
              v-col(v-for="(wevent, b) in weventsForm" :key="b" cols="12")
                v-card
                  v-card-text
                    v-row
                      v-col(cols="12" md="6")
                        v-select(v-model="wevent.type" :label="$tc('label.evenement', 1)" @change="changeWeventType(wevent)" :items="weventKindsOrdered"  :rules="rules" item-text="description" item-value="id")
                          template(v-slot:item="data")
                            v-icon {{ gWeventIcon(data.item.id) }}
                            span.wevent-type__label {{ $t(data.item.description) }}
                          template(v-slot:selection="data")
                            v-icon {{ gWeventIcon(data.item.id) }}
                            span.wevent-type__label {{ $t(data.item.description) }}
                      v-col(cols="4" md="2")
                        v-switch(v-model="wevent.is_alert" :label="$tc('label.alert', 1)" )
                      v-spacer
                      v-col(cols="8" md="4" v-show="wevent.is_alert")
                        v-select(v-model="wevent.severity" :label="$t('label.level')" :error="errorSeverity(wevent)" :items="alertsSeverity" item-text="label" item-value="value" )
                      v-col(cols="12" md="6")
                        vue-ctk-date-time-picker(
                          id="wevent-start"
                          ref="startDate"
                          :value="$moment(wevent.time_start *1000).tz(currentZone.timezone).format('YYYY-MM-DD HH:mm')"
                          @input="wevent.time_start = $moment.tz($event, currentZone.timezone).unix()"
                          :error="wevent.validAttempt && !wevent.time_start"
                          :label="$t('label.startAt')"
                          format="YYYY-MM-DDTHH:mm"
                          minute-interval=15
                          :no-button-now="true"
                          position="top")
                      v-col(cols="12" md="6")
                        vue-ctk-date-time-picker(
                          id="wevent-end"
                          ref="endDate"
                          :value="$moment(wevent.time_end *1000).tz(currentZone.timezone).format('YYYY-MM-DD HH:mm')"
                          @input="($refs.endDate && $refs.endDate[b] && $refs.endDate[b].hasPickerOpen) ? wevent.time_end = $moment.tz($event, currentZone.timezone).unix() : null"
                          :error="wevent.validAttempt && (!wevent.time_end || (wevent.time_end && wevent.time_start && wevent.time_end <= wevent.time_start))"
                          :label="$t('label.endAt')" :min-date="$moment(wevent.time_start *1000).tz(currentZone.timezone).format('YYYY-MM-DD HH:mm')"
                          format="YYYY-MM-DDTHH:mm"
                          minute-interval=15
                          :no-button-now="true"
                          position="top")
                      template(v-if="wevent.cat")
                        v-col(md="6")
                          v-text-field(outlined :label="getWeventCatLabel(wevent,0)"  type="number" :value="wevent.range[0]" @change="$set(wevent.range,0,$event)" :min="wevent.cat.min" :max="wevent.cat.max" :step="wevent.cat.step")
                        v-col(md="6")
                          v-text-field(outlined :label="getWeventCatLabel(wevent,1)" type="number" :value="wevent.range[1]"  @change="$set(wevent.range,1,$event)" :min="wevent.cat.min" :max="wevent.cat.max" :step="wevent.cat.step")
                        v-col(v-if="wevent.cat.id != 'tstm'" cols="12")
                          v-range-slider.mt-5(
                                v-model="wevent.range"
                                :min="wevent.cat.min"
                                :max="wevent.cat.max"
                                hide-details
                                track-color="grey"
                                thumb-color="primary"
                                color="primary"
                                :step="wevent.cat.step"
                                thumb-label="always"
                                class="align-center")
                    v-row(v-show="wevent.is_alert")
                      v-col(cols="12")
                        v-divider
                      v-col(md="12")
                        v-textarea(v-model="wevent.message" hide-details filled clearable :label="$t('label.message')")
                      v-col.pt-0(md="12")
                        v-chip.cursor-pointer.mr-4( v-for="template in weventAlertTemplates" :key="template.name" small outlined @click="wevent.message += template.text + \'\\n\'")
                          | {{ template.name }}

                  v-card-actions
                    v-spacer
                    v-btn(color="warning" @click="remove(b)")
                      v-icon mdi-delete
            v-snackbar(v-model="errors" bottom right color="error")
              | {{ $t('error.save') }}
v-overlay(v-else v-model="gIsLoading")
  v-progress-circular(indeterminate)
</template>

<script>
import WeventIcon from '@/components/Castzones/WEventIcon'
import WeventSuggestTable from '@/components/Castzones/WEventSuggestTable'
import {mapActions} from 'vuex'
import {getWeventCat} from '@/utils/wevents'

export default {
  components: {
    WeventIcon,
    WeventSuggestTable,
  },
  data() {
    return {
      alertsSeverity: [
        { label: this.$t('alert.severity.minor'), value: 1 },
        { label: this.$t('alert.severity.moderate'), value: 2 },
        { label: this.$t('alert.severity.severe'), value: 3 },
        { label: this.$t('alert.severity.extreme'), value: 4 }
      ],
      weventsForm: [],
      success:false,
      errors:false,
      needValidate:false,
      rules: [(value) => ( (value === 0 || (!!value && value.toString().length > 0)) )],
      saving:false,
      weventAlertTemplates: [
          {name:'Acc. Métro', text:'ÉVÉNEMENT MÉTÉO SÉVÈRE : FORTE ACCUMULATION DE 25 CM DE NEIGE EN 24 HEURES'},
          {name:'Acc. Autre', text:'ÉVÉNEMENT MÉTÉO SÉVÈRE : FORTE ACCUMULATION DE 30 CM DE NEIGE EN 24 HEURES'},
          {name:'Inten. 5cm/h', text:'ÉVÉNEMENT MÉTÉO SÉVÈRE : FORTE INTENSITÉ DE NEIGE DE 5 CM/H PENDANT 3 HEURES'},
          {name:'Inten. H.Pointe', text:'ÉVÉNEMENT MÉTÉO SÉVÈRE : FORTE INTENSITÉ DE NEIGE, DÉBUTANT AUX HEURES DE POINTE, DE 3 CM/H PENDANT 3 HEURES'},
          {name:'Vents Forts', text:'ÉVÉNEMENT MÉTÉO SÉVÈRE : VENTS FORTS DE 50 KM/H AVEC NEIGE PENDANT 3 HEURES'},
          {name:'Pluie Verglaçante', text:'ÉVÉNEMENT MÉTÉO SÉVÈRE : IMPORTANTE PLUIE VERGLAÇANTE DE 3 MM/H PENDANT 3 HEURES'}
      ]
    }
  },
  computed: {
    weventKindsOrdered() {
      return [
        ...this.weventKinds.filter(wk => wk.used>0).sort((a,b)=> a.used>b.used),
        { divider: true },
        ...this.weventKinds.filter(wk => wk.used==0).sort((a,b)=> a.description.localeCompare(b.description)) ]
    },
    needPublish() {
      return (this.weventsForm.length>0 || this.weventsZoneUpdate.length>0)
    },
    weventsZone() {

      if(!this.currentZone) return []

      const wevents = this.gGetWevents(this.currentZone)

      return wevents.filter(w => w.time_publish || w.id_castzone != this.currentZone.id).sort((w1, w2) => {
        return w1.time_start < w2.time_start ? -1 : 1
      })
    },
    weventSuggestsZone() {
      if(!this.currentZone) return []

      const suggests = []

      /* on recupere toutes les suggestions des castpoints de la zone */
      for(let cp of this.currentZone.castpoints)
        cp.weventSuggests && cp.weventSuggests.map(ws => suggests.push({...ws, id_castpoint : cp.id, kind: this.weventKind(ws.id_kind)}))

      /* on cree une liste unique des id de types d'evenement avec en premier les types alertes pour creer un cle de regroupement pour le v-data-table */
      const kindsAlert = new Set()
      const kindsOther = new Set()

      for(let s of suggests) {
        if(s.kind && s.kind.id) {
          (s.kind.is_alert) ? kindsAlert.add(s.kind.id) : kindsOther.add(s.kind.id)
        }
      }
      const kinds = {}

      // eslint-disable-next-line no-unused-vars
      let tmp = [...Array.from(kindsAlert).sort(),...Array.from(kindsOther).sort()].map((a,i) => kinds[a] =  String(i+1).padStart(3, '0') + '##' + a )

      return suggests.map(s => { return {...s,group_key:kinds[s.id_kind]}} )

    },
    weventsZoneUpdate() {
      return this.weventsZone.filter(w => w.will_be_active_set_by_me)
    },
    weventsZoneWillArchive() {
      return this.weventsZone.filter(w => this.weventsForm.find(wf=> wf.id_parent == w.id && w.id_castzone == this.currentZone.id  ))
    }
  },
  watch: {
    'currentZoneId': {
      immediate: true,
      handler() {
        this.loadDraft()
      }
    },
    'editZone': {
      immediate: true,
      handler(val) {
        val ? this.loadDraft() : this.saveDraft()
      }
    },
  },
  methods: {
    getWeventCat,
    ...mapActions(['archiveWevent', 'createWevent', 'updateWevent',
       'deleteWevent', 'setStateWeventZone', 'publishWeventCastzone']),
    getWeventCatLabel(wevent, valueId) {
      if(wevent.cat) {
        if(wevent.cat.id == 'tstm') {
          return (valueId === 0) ? this.$t('label.evenementValueLabelTstmRisk') :  this.$t('label.evenementValueLabelTstmSev')
        }

        return (valueId === 0) ? this.$t('label.evenementValueLabelMin', {unit:wevent.cat.unit}) :  this.$t('label.evenementValueLabelMax', {unit:wevent.cat.unit})
      }
      return ''
    },
    alertsSeverityLabel(severity) {
      const alertsSeverity = this.alertsSeverity.find(a => a.value == severity)
      return alertsSeverity ? alertsSeverity.label : ''
    },
    errorSeverity(wevent) {
      if(!wevent.is_alert) return false
      return !wevent.severity
    },
    async setState(wevent, state, confirm = true) {

      let msg = !state ? 'Désactiver ' : 'Activer '
      msg += 'l\'évènement ' + this.weventKind(wevent.type).description
      msg += ' de la zone ' + this.gGetZoneById(wevent.id_castzone).name
      msg += ' sur la zone ' + this.currentZone.name + ' ?'
      const title = !state ? 'Désactivation ' : 'Activation'
      if(confirm && !await this.gGetConfirm(title, msg)) return
      this.gLoading(title)
      await this.setStateWeventZone({id : wevent.id, id_castzone: this.currentZone.id, state: state})
          .then(() => {
            this.errors = false
          }).catch( () => {
            this.errors = true
          })
      this.gLoadingEnd()
    },
    canRenew(wevent) {
      return !this.weventsForm.find( w => w.id_parent == wevent.id)
    },
    async renew(wevent) {

      if(wevent.id_castzone != this.currentZone.id) {
        this.setState(wevent, false, false)
      }

      this.weventsForm.push({
        id: null,
        time_start: wevent.time_start,
        time_start_lock: (wevent.time_start<this.$moment().unix()),
        time_end: wevent.time_end,
        id_castzone: this.currentZone.id,
        severity: wevent.severity,
        message: wevent.message,
        id_parent: wevent.id,
        ref_wevent: wevent.ref_wevent,
        is_alert: wevent.is_alert,
        type: wevent.type,
        range: [wevent.value_min,wevent.value_max],
        value_min:wevent.value_min,
        value_max:wevent.value_max,
        cat: wevent.cat,
        validAttempt: false,
      })

      await this.saveDraft()

    },
    async clone(wevent) {

      this.weventsForm.push({
        id: null,
        time_start: wevent.time_start,
        time_end: wevent.time_end,
        id_castzone: this.currentZone.id,
        severity: wevent.severity,
        message: wevent.message,
        id_parent: null,
        ref_wevent: null,
        is_alert: wevent.is_alert,
        type: wevent.type,
        range: [wevent.value_min,wevent.value_max],
        value_min:wevent.value_min,
        value_max:wevent.value_max,
        cat: wevent.cat,
        validAttempt: false,
      })

      await this.saveDraft()

    },
    async add() {
      const m = (this.weventsForm.length>0) ? this.$moment(this.weventsForm[this.weventsForm.length-1].time_end * 1000) : this.$moment()
      const mStart = m.clone()
      m.minute() || m.second() || m.millisecond() ? mStart.add(1, 'hour').startOf('hour') : mStart.startOf('hour')
      const mEnd = mStart.clone().add(3,'hour')
      this.weventsForm.push({
        id: null,
        time_start: mStart.unix(),
        time_end: mEnd.unix(),
        id_castzone: this.currentZone.id,
        severity: null,
        message: '',
        id_parent: null,
        ref_wevent: null,
        is_alert: false,
        type: null,
        range: [null,null],
        cat: null,
        validAttempt: false,
      })

    },
    async generateFromSuggest(suggest) {

      const weventKind = this.weventKind(suggest.id_kind)
      if(!weventKind) return false

      const weventCat = (weventKind.cat) ? getWeventCat(weventKind.cat) : null


      this.weventsForm.push({
        id: null,
        time_start: suggest.time_start,
        time_end: suggest.time_end,
        id_castzone: this.currentZone.id,
        severity: suggest.severity,
        message: weventKind.template_alert,
        id_parent: null,
        ref_wevent: null,
        is_alert: weventKind.is_alert,
        type: suggest.id_kind,
        range: [
            weventCat && suggest.value? Math.floor(suggest.value*0.92) : null,
            weventCat && suggest.value? Math.ceil(suggest.value*1.08) : null,
            ],
        cat: weventCat,
        validAttempt: false,
      })
    },
    changeWeventType(wevent) {
      const weventKind = this.weventKind(wevent.type)
      const weventCat = getWeventCat(weventKind.cat)
      wevent.range = weventCat ? [weventCat.v1,weventCat.v2] : [null, null]
      wevent.cat = weventCat
      wevent.is_alert = weventKind.is_alert
    },
    deleteForm(idx) {
      this.weventsForm.splice(idx,1)
    },
    async archive(id) {
      if(!await this.gGetConfirm(null, this.$t('confirmDialog.confirmArchive'))) return
      try{
        this.gLoading()
        await this.archiveWevent(id)
      }catch(err){
        this.errors = true
      } finally {
        this.gLoadingEnd()
      }
    },
    async remove(idx) {
      if(!await this.gGetConfirm(null, this.$t('confirmDialog.confirmDelete'))) return
      if(this.weventsForm[idx].id) {
        this.gLoading('Suppression du brouillon')
        await this.deleteWevent(this.weventsForm[idx])
          .then((del) => {
            del && this.deleteForm(idx)
            this.errors = !del
          }).catch( () => {
            this.errors = true
          })
        this.gLoadingEnd()
      } else {
        this.deleteForm(idx)
      }
    },
    canClose() {
      for (var i = 0; i < this.weventsForm.length; i++) {
        this.weventsForm[i].validAttempt = true
        if(!this.weventsForm[i].time_start
          || !this.weventsForm[i].time_end
          || (this.weventsForm[i].time_start && this.weventsForm[i].time_end && (this.weventsForm[i].time_end <= this.weventsForm[i].time_start )  )
          || this.errorSeverity(this.weventsForm[i])
        )
        {
          this.gGetConfirm(this.$t('confirmDialog.errorTitle'), this.$t('confirmDialog.errorForm'), {noconfirm: true, color: 'error'})
          return false
        }

      }
      return this.$refs.form.validate()
    },
    async publish() {
      if(this.canClose()){

        await this.saveDraft()

        this.gLoading('Publication des évènements')

        const success = await this.publishWeventCastzone(this.currentZone.id)

        if (success) {
          this.loadDraft()
        }
        else {
          this.gGetConfirm(this.$t('confirmDialog.errorTitle'), this.$t('confirmDialog.errorSystem'), {noconfirm: true, color: 'error'})

        }

        this.gLoadingEnd()
      }
    },
    loadDraft() {
      if(this.currentZone) {
        const wevents = this.gGetWevents(this.currentZone)
        this.weventsForm = []
        wevents.filter(w => !w.time_publish && w.id_castzone == this.currentZone.id).forEach(w => {
          this.weventsForm.push({
            id: w.id,
            time_start: w.time_start,
            time_start_lock: (w.time_start<this.$moment().unix()),
            time_end: w.time_end,
            id_castzone: w.id_castzone,
            severity: w.severity,
            message: w.message,
            id_parent: w.id_parent,
            ref_wevent: w.ref_wevent,
            is_alert: w.is_alert,
            type: w.type,
            range: [w.value_min,w.value_max],
            cat: w.cat,
            validAttempt: false,
          })
        })
      } else {
        this.weventsForm = []
      }
    },
    async moveToZone(zoneId) {
      await this.saveDraft()
      this.gLoadZone({id:zoneId,show:true,route:true})
    },
    async saveDraft() {
      if(!this.saving) {
        this.saving=true
        this.gLoading('Sauvegarde des brouillons')
        try {
          for (var i = 0; i < this.weventsForm.length; i++) {
            this.weventsForm[i].value_min = this.weventsForm[i].range[0]
            this.weventsForm[i].value_max = this.weventsForm[i].range[1]
            if (this.weventsForm[i].id) {
              await this.updateWevent(this.weventsForm[i])
            } else {
              await this.createWevent(this.weventsForm[i])
            }
          }
          this.loadDraft()
        }
        finally {
          this.gLoadingEnd()
          this.saving=false
        }

        /* /!\ try sans cacth : jamais executé en cas d'erreur  */
      }
    },
  },
}
</script>

<style>
  .wevent-archive-witdh {
    width: 50px
  }
  .wevent-progress-witdh {
    width: 150px
  }
  .wevent-type__label {
    padding-left: 6px;
  }
  .wevents-content {
    align-content: flex-start;
  }

  @media screen and (min-width: 1904px) {
    .wevents-content {
      min-height: 800px;
    }
  }

  .wevents-content .will-be-disabled td:not(.actions) {
    text-decoration-line: line-through;
  }
</style>
