<template lang="pug">
  v-data-table(
    dense
    :headers="[{text:$t('label.type'), value:'kind_name'}, {text:$t('label.castpoint'), value:'id_castpoint'}, {text:$t('label.startAt'), value:'time_start'},{text:$t('label.endAt'), value:'time_end'},{text:$t('label.value'), value:'value'}, {text:'',value:'id'} ]"
    :items="weventSuggests"
    group-by="group_key"
    sort-by='time_start'
    item-key="id"
    :hide-default-footer="true"
    :disable-pagination="true"
  )
    template(v-slot:group.header="{group,toggle,isOpen}")
      td.pl-2(colspan=6)
        v-icon.mr-1(v-if="isOpen" small @click="toggle" :ref="'SG_' + group"  color="primary")
          | mdi-minus
        v-icon.mr-1(v-else small @click="toggle" color="primary")
          | mdi-plus
        v-icon.mr-1(
          v-if="weventKind(group.replace(/\\d{3}##(.*)/g, '$1')).is_alert"
          :color="gWeventColorSeverity(3)"
          small)
          | {{ gWeventIcon(group.replace(/\d{3}##(.*)/g, '$1')) }}
        span
          | {{ weventKind(group.replace(/\d{3}##(.*)/g, '$1')).description }}
    template(v-slot:item.id_castpoint="{ item }")
      span
        | {{ currentZone.castpoints.find(cp => cp.id == item.id_castpoint).name }}
      v-btn.ml-1( @click.stop="gForecastCastpoint(item.id_castpoint)" text x-small color="lightgrey")
        v-icon(x-small color="primary" ) mdi-chart-areaspline

    template(v-slot:item.time_start="{ item }")
      span
        | {{ $moment(item.time_start *1000).tz(currentZone.timezone).format('DD/MM/YYYY HH:mm') }}

    template(v-slot:item.time_end="{ item }")
      span
        | {{ $moment(item.time_end *1000).tz(currentZone.timezone).format('DD/MM/YYYY HH:mm') }}

    template(v-slot:item.value="{ item }")
      template(v-if="item.value && getWeventCat(weventKind(item.id_kind).cat)")
        span
          | {{ item.value }}
        span(v-if="weventKind(item.id_kind).cat")
          | &nbsp;{{ getWeventCat(weventKind(item.id_kind).cat).unit }}
      span(v-else)
        | -

    template(v-slot:item.id="{ item }")
      v-btn(x-small @click="generate(item)")
        | {{ $t('button.generate') }}
</template>

<script>
import {getWeventCat} from '@/utils/wevents'

export default {
  props:{
    'weventSuggests':{
      type:Array,
      default:()=> []
    }
  },
  watch: {
    'currentZone': {
      immediate: true,
      handler() {
        /* code pour fermer tout les group par default en appelant les refs prefixées par SG_ */
        this.$nextTick(() => {
          Object.keys(this.$refs).forEach(k => {
            if (k.indexOf('SG_') === 0 && typeof (this.$refs[k]) !== 'undefined' && this.$refs[k].$el)
              this.$refs[k].$el.click()
          })
        })
      }
    }
  },
  methods: {
    getWeventCat,
    generate(item) {
      this.$emit('generate',item)
    }
  },
}
</script>

<style scoped>

</style>
